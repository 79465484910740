<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'supplier-create create-form'"
    v-if="getPermission('contact:create')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <h1 class="form-title d-flex mr-3">Create a new Contact</h1>
        <!-- <Barcode :barcode="getBarcode"></Barcode> -->
      </div>
    </template>
    <template v-slot:header-action>
      <v-btn
        depressed
        v-on:click="goBack"
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        depressed
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="onSubmit"
        color="cyan"
      >
        Save Contact
      </v-btn>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow product-create-height"
        style="position: relative"
      >
        <v-form
          ref="supplierForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <ContactDetails
            v-on:saveContactDetail="updateContactDetail"
            :options="options"
          ></ContactDetails>
        </v-form>
      </perfect-scrollbar>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  
  PUT,
  CLEAR_ERROR,
  GET,
} from "@/core/services/store/request.module";
import ContactDetails from "@/view/pages/contact/create-or-update/Contact-Details.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";

export default {
  mixins: [CommonMixin],
  name: "contact-create",
  title: "Create Contact",
  data() {
    return {
      supplierArr: {},
      billingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      barcode: null,
      steps: {
        details: false,
        "contact-person": true,
        property: true,
        dblock: false,
        billing: true,
        notification: true,
      },
      supplierTab: "tab-details",
      isSupplierCreated: false,
      isAddressCreated: false,
      supplierId: 0,
      options: {},
    };
  },
  components: {
    ContactDetails,
    CreateUpdateTemplate,
  },
  watch: {
    supplierTab() {
      const _this = this;
      setTimeout(function () {
        _this.$refs.supplierForm.validate();
        _this.$nextTick(() => {
          _this.activateTab();
        });
      }, 200);
    },
    formValid(param) {
      if (param) {
        this.activateTab();
        this.$store.dispatch(CLEAR_ERROR, {});
      }
    },
  },
  methods: {
    activateTab() {
      if (this.formValid) {
        if (this.supplierTab == "tab-details") {
          this.steps["contact-person"] = false;
        }

        if (this.supplierTab == "tab-contact-person") {
          this.steps["billing"] = false;
        }

        if (this.supplierTab == "tab-billing") {
          this.steps["billing"] = false;
        }

        /* if (this.supplierTab == "tab-billing") {
          this.steps["notification"] = false;
        } */
      }
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
      
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "supplier/options" })
        .then(({ data }) => {
          _this.options = data.options;
          _this.barcode = data.barcode;
          _this.options.active_zones = data.active_zones;
          _this.options.all_tags = data.all_tags;
          _this.options.all_cms_categories = data.all_cms_categories;
        })
        .catch((error) => {
          _this.logError(error);
        });
      /*.finally(() => {
          _this.$refs.supplierForm.validate();
        });*/
    },
    createOrUpdatedAddress(contact) {
      return new Promise((resolve, reject) => {
        try {
          if (contact) {
            const _this = this;
            const addressArr = [];
            if (_this.lodash.isEmpty(_this.billingAddress) === false) {
              addressArr.push(_this.billingAddress);
            }

            if (_this.lodash.isEmpty(addressArr) === false) {
              const formData = { address: addressArr, type: "contact" };
              _this.$store
                .dispatch(PUT, { url: "address/" + contact, data: formData })
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              resolve(true);
            }
          } else {
            resolve(true);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    makeDefaultPerson() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          let emailData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 1;
            }
          );
          let contactData = _this.lodash.filter(
            _this.contactDetail.details,
            function (data) {
              return data.type === 2;
            }
          );
          let firstEntry = {
            id: null,
            title: _this.contactDetail.salutation,
            first_name: _this.contactDetail.first_name,
            last_name: _this.contactDetail.last_name,
            will_notified: 1,
            position: null,
            display_name: _this.createDisplayName(),
            primary_email: _this.lodash.head(emailData)
              ? _this.lodash.head(emailData).value
              : null,
            primary_phone: _this.lodash.head(contactData)
              ? _this.lodash.head(contactData).value
              : null,
            default: 1,
          };
          resolve([firstEntry]);
        } catch (error) {
          reject(error);
        }
      });
    },
    createDisplayName() {
      const _this = this;
      let firstname = "";
      if (_this.contactDetail.first_name) {
        firstname = _this.contactDetail.first_name.toLowerCase();
      }
      let lastname = "";
      if (_this.contactDetail.last_name) {
        lastname = _this.contactDetail.last_name.toLowerCase();
      }
      return firstname + lastname;
    },
    async onSubmit() {
      const _this = this;
   
      try {
        const formErrors = _this.validateForm(_this.$refs.supplierForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        // if (_this.lodash.isEmpty(_this.billingAddress)) {
        //   _this.supplierTab = "tab-billing";
        //   ErrorEventBus.$emit(
        //     "update:error",
        //     InitializeError("Company address is required")
        //   );
        //   return false;
        // }

        if (!_this.$refs.supplierForm.validate()) {
          return false;
        }

        //_this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        if (_this.lodash.isEmpty(_this.contactPerson)) {
          _this.contactPerson = await _this.makeDefaultPerson();
        }

        let formData = _this.contactDetail;

        if (_this.lodash.isEmpty(_this.contactDetail.profile_logo) === false) {
          // formData.profile_logo = _this.contactDetail.profile_logo.id;
        }

        // if (_this.lodash.isEmpty(_this.contactPerson) === false) {
        //   formData.persons = _this.contactPerson;
        // }

      

        
        _this.formLoading = true;
        try {
          if (!_this.isSupplierCreated) {

            let supplierObject = await _this.$store.dispatch(PUT, {
              url: `customer/${this.contactDetail.company_id}/contact-person`,
              data: { persons: [formData] },
            });
            _this.supplierId = supplierObject.data.id;
            
            _this.isSupplierCreated = true;
          }
        } catch (error) {
          ErrorEventBus.$emit(
            "update:error",
            "Contact is not created. Please try again."
          );
        }

        try {
          if (!_this.isAddressCreated && _this.supplierId) {
            await _this.createOrUpdatedAddress(_this.supplierId);
            _this.isAddressCreated = true;
          }
        } catch (error) {
          _this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            "Property or Company Address is not created. Please try again."
          );
          return false;
        }

        _this.formLoading = false;

        if (_this.isSupplierCreated) {
        
          _this.$router.push(
            _this.getDefaultRoute("contact.detail", {
              params: { id: _this.supplierId },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
  },
  computed: {
    ...mapGetters(["gCreateFormFields"]),
  },
  mounted() {
    this.getOptions();
    this.$refs.supplierForm.validate();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Contact", route: "contact" },
      { title: "Create" },
    ]);
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.isSupplierCreated) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
<style scoped>
/* tabs css  */

.custom-tab-transparent.v-tabs--fixed-tabs > .v-tabs-bar .v-tab.v-tab--active {
  background-color: #00bcd4 !important;
  color: #fff !important;
}
.custom-tab-transparent.v-tabs--fixed-tabs
  > .v-tabs-bar
  .v-tab.v-tab--active
  .grey--text.text--darken-4 {
  color: #fff !important;
  caret-color: #fff !important;
}

.custom-tab-transparent.v-tabs .v-tab {
  background: #00bcd429;
  width: 25%;
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}

.custom-tab-transparent.v-tabs .v-tab.v-tab:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + -0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
